import React, { useState, useEffect, useRef } from 'react';
import './memeStyles.css';

function ImageUploader() {
    const defaultSize = 100;

    const [imageSrc, setImageSrc] = useState(null);
    const [squarePosition, setSquarePosition] = useState({ x: 0, y: 50 });
    const [squareSize, setSquareSize] = useState(defaultSize);
    const [imageRotation, setImageRotation] = useState(0);
    const [canvasVisible, setCanvasVisible] = useState(false);
    const [isFlipped, setIsFlipped] = useState(false);
    const canvasRef = useRef(null);
    const isDraggingRef = useRef(false);
    const isResizingRef = useRef(false);
    const isRotatingRef = useRef(false);
    const lastMousePositionRef = useRef({ x: 0, y: 0 });
    const doughImageRef = useRef(new Image());

    const canvasWidth = 600;
    const canvasHeight = 600;

    const squareBoundary = {
        left: squarePosition.x,
        right: squarePosition.x + squareSize,
        top: squarePosition.y - 50,
        bottom: squarePosition.y + squareSize - 50,
    };

    const dropZoneRef = useRef(null);

    const hotbarImages = [
        'dough.png',
        'Doughboi_1.png',
        'Doughboi_2.png',
        'Doughboi_3.png',
        'Doughboi_4.png',
        'PD_expression_2.png',
        'PD_expression_3.png',
        'PD_expression_4.png',
        'PD_expression_5.png',
        'PD_expression_6.png',
    ];

    const handleHotbarImageClick = (imageSource) => {
        doughImageRef.current.src = imageSource;
        setCanvasVisible(true);
        if (imageSrc) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');

            const image = new Image();
            image.src = imageSrc;

            image.onload = () => {
                updateCanvas(canvas, ctx, image);
            };
        }
    };

    const updateCanvas = (canvas, ctx, image) => {
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        ctx.clearRect(0, 0, canvasWidth, canvasHeight);

        const scaleX = canvasWidth / image.width;
        const scaleY = canvasHeight / image.height;
        const scale = Math.min(scaleX, scaleY);

        const centerX = (canvasWidth - image.width * scale) / 2;
        const centerY = (canvasHeight - image.height * scale) / 2;

        ctx.drawImage(image, centerX, centerY, image.width * scale, image.height * scale);

        ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
        const barHeight = 100;
        const cornerRadius = 25;


        ctx.save();
        ctx.translate(squarePosition.x + squareSize / 2, squarePosition.y + squareSize / 2);
        ctx.rotate((imageRotation * Math.PI) / 9000);

        // Flip the image if isFlipped is true
        if (isFlipped) {
            ctx.scale(-1, 1);
        }

        ctx.drawImage(
            doughImageRef.current,
            (-squareSize / 2) + 5,
            (-squareSize / 2) - 40,
            squareSize,
            squareSize
        );
        ctx.restore();
    };

    useEffect(() => {
        if (imageSrc) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');

            const image = new Image();
            image.src = imageSrc;

            image.onload = () => {
                updateCanvas(canvas, ctx, image);
            };
        }
    }, [imageSrc, squarePosition, squareSize, imageRotation, isFlipped, canvasWidth, canvasHeight]);

    const handleImageDrop = (event) => {
        event.preventDefault();

        // Check if files were dropped
        const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;

        if (files && files.length > 0) {
            const file = files[0];

            const reader = new FileReader();

            reader.onload = (e) => {
                setImageSrc(e.target.result);
                setCanvasVisible(true);
            };

            reader.readAsDataURL(file);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleMouseDown = (e) => {
        const mouseX = e.nativeEvent.offsetX;
        const mouseY = e.nativeEvent.offsetY;

        if (e.ctrlKey) {
            isResizingRef.current = true;
        } else if (e.shiftKey) {
            isRotatingRef.current = true;
            lastMousePositionRef.current = { x: mouseX, y: mouseY };
        } else if (
            mouseX >= squareBoundary.left &&
            mouseX <= squareBoundary.right &&
            mouseY >= squareBoundary.top &&
            mouseY <= squareBoundary.bottom
        ) {
            isDraggingRef.current = true;
            lastMousePositionRef.current = { x: mouseX, y: mouseY };
        }
    };

    const handleMouseMove = (e) => {
        if (isResizingRef.current) {
            const newMousePosition = { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY };
            const offsetX = newMousePosition.x - lastMousePositionRef.current.x;
            const offsetY = newMousePosition.y - lastMousePositionRef.current.y;

            const newSize = squareSize + offsetX;
            if (newSize > 0) {
                setSquareSize(newSize);
            }

            lastMousePositionRef.current = newMousePosition;
        } else if (isRotatingRef.current) {
            const newMousePosition = { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY };
            const deltaX = newMousePosition.x - lastMousePositionRef.current.x;
            const deltaY = newMousePosition.y - lastMousePositionRef.current.y;
            const angle = Math.atan2(deltaY, deltaX);
            const newRotation = (angle * 180) / Math.PI;
            setImageRotation((prevRotation) => prevRotation + newRotation);
            lastMousePositionRef.current = newMousePosition;
        } else if (isDraggingRef.current) {
            const newMousePosition = { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY };
            const offsetX = newMousePosition.x - lastMousePositionRef.current.x;
            const offsetY = newMousePosition.y - lastMousePositionRef.current.y;

            setSquarePosition((prevPosition) => ({
                x: prevPosition.x + offsetX,
                y: prevPosition.y + offsetY,
            }));

            lastMousePositionRef.current = newMousePosition;
        }
    };

    const handleMouseUp = () => {
        isDraggingRef.current = false;
        isResizingRef.current = false;
        isRotatingRef.current = false;
    };

    useEffect(() => {
        doughImageRef.current.src = 'dough.png'; // Replace with the correct path to dough.png
    }, []);

    const handleKeyDown = (e) => {
        if (e.key === 'Tab') {
            e.preventDefault();
            setIsFlipped((prevIsFlipped) => !prevIsFlipped);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleSaveImage = () => {
        // Get the canvas element and convert it to a data URL
        const canvas = canvasRef.current;
        const dataURL = canvas.toDataURL();

        // Create a temporary anchor element to trigger the download
        const a = document.createElement('a');
        a.href = dataURL;
        a.download = 'image.png';
        document.body.appendChild(a);

        // Trigger a click event on the anchor element to start the download
        a.click();

        // Clean up by removing the temporary anchor element
        document.body.removeChild(a);
    };

    return (
        <div className='centered'>
            <h1 className='header-container'>
                <div className="header-text">LETS COOK SOME</div>
                <div className="glitch">
                    <span aria-hidden="true">$DOUGH</span>
                </div>
            </h1>
            <div className='desc' style={{ display: !imageSrc ? "none" : "" }}>Hold CNTRL + drag to scale. Hold shift + drag to rotate. Hit tab to flip</div>
            <div
                className="drop-zone"
                ref={dropZoneRef}
                onDrop={handleImageDrop}
                onDragOver={handleDragOver}
                style={{ display: !imageSrc ? "" : "none" }}
                onClick={() => {
                    // Trigger the file selection dialog when the drop zone is clicked
                    const fileInput = dropZoneRef.current.querySelector('input[type="file"]');
                    fileInput.click();
                }}
            >
                <input
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }} // Hide the file input visually
                    onChange={handleImageDrop} // Handle file selection
                />
                <p>Click or drop an image here</p>
            </div>

            <div class="hotbar" style={{ display: !imageSrc ? "none" : "" }}>
                {hotbarImages.map((image, index) => (
                    <div
                        key={index}
                        class="hotbar-slot"
                        onClick={() => handleHotbarImageClick(image)}
                    >
                        <img src={image} alt={`Slot ${index + 1}`} />
                    </div>
                ))}
            </div>
                    
            <button onClick={handleSaveImage}
                    className="save"
                    style={{ display: !imageSrc ? "none" : "" }}>
                        Save Image
            </button>

            {canvasVisible && imageSrc && (
                <div>
                    <canvas
                        ref={canvasRef}
                        id="canvas"
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                    />
                </div>
            )}
            
        </div>
    );
}

export default ImageUploader;
