import React from 'react';

const Header = ({ onButtonClick }) => (
  <div className="header">
    <a className="logo-title-container">
      <img src='Dough_logo.png' className='logo' alt="Logo"></img>
      <span className='titleHead'>F**k B*tches, Get $DOUGH!!</span>
    </a>
    <a href="#" className="kitchen" onClick={onButtonClick}>
      <div className="kitchen-text">
        <span className="old-text">The Kitchen</span>
        <span className="new-text">Visit Now</span>
      </div>
      <img src="https://i.imgur.com/sjbs1m9.png" alt="Arrow" className="arrow-icon" />
    </a>
  </div>
);

export default Header;
