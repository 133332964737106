import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import useWindowDimensions from './useWindowDimensions';

function MainContent({ onButtonClick }) {

  const audioRef = useRef(null);
  const {height, width} = useWindowDimensions();
  const [playing, setPlaying] = useState(false);
  const [step, setStep] = useState(1);

  const toggleAudio = () => {
    setPlaying(!playing);
    const audio = document.getElementById('background-audio');
    playing ? audio.pause() : audio.play();
  };

  const playAudio = () => {
    const audio = document.getElementById('background-audio');
    audio.play();
    setPlaying(true);
    document.removeEventListener('click', playAudio);
    document.removeEventListener('touchstart', playAudio);
    document.removeEventListener('keydown', playAudio);
  };

  useEffect(() => {
    const handleEvent = (e) => {
        if (e.target.classList.contains('reveal') || e.target.classList.contains('reveall')) {
            playAudio();
        }
    };

    document.addEventListener('click', handleEvent);
    document.addEventListener('touchstart', handleEvent);
    // The keydown event doesn't make sense in the context of clicking on an element, so I've removed it.
    
    return () => {
        document.removeEventListener('click', handleEvent);
        document.removeEventListener('touchstart', handleEvent);
    };
}, []);

  return (
    <div className="main">
      <audio id="background-audio" loop>
        <source src={`music.mp3`} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
      <img src="https://i.imgur.com/cU3trJQ.png" className='blur' width="100%" height="100%"></img>
      <div className='let-him-cook'>
        <img className="cook"  style={{width: ((width+1813.33333333)/37.3333333) + "%"}} src='lethimcook.png'></img>
        <div className='text' style={{fontSize: (width + 2960) / 300 + "px", width: ((width + 1513.33333333333) / 8.33333333333) + "px"}}>
          <strong>$DOUGH</strong> - A.K.A. The Big D is not a token, its a movement. Created only for Big D ballers that embody The Big D energy! 
          Yo mama loves The Big D. Yo sister loves The Big D. Even yo grandma, daddy, and friends love The Big D - and soon, you will too!
        </div>
        <a href="#" class="button">
          <div class="button-text">
            <span class="old-text">BUY $DOUGH</span>
            <span class="new-text">Coming Soon</span>
          </div>
          <img src="https://i.imgur.com/sjbs1m9.png" alt="Arrow" class="arrow-icon"></img>
        </a>
        <div className="socials-box">
            <h3>FOLLOW OUR SOCIALS</h3>
            <div className="social-item">
                <div className="social-icon telegram"><img src='https://i.imgur.com/9oZAxNW.png'></img></div>
                <span onClick={() => window.open('https://t.me/doughportal', '_blank')} >Telegram</span>
            </div>
            <div className="social-item">
                <div className="social-icon twitter"><img src='https://i.imgur.com/inFPDht.png'></img></div>
                <span onClick={() => window.open('https://twitter.com/doughcoineth', '_blank')} >Twitter</span>
            </div>
            <div className="social-item">
                <div className="social-icon twitter"><img src='dextools.png'></img></div>
                <span onClick={() => window.open('https://www.dextools.io/app/en/ether/pair-explorer/0xc06ffa4385732ea058e799c44696c11138819bd3', '_blank')} >Dextools</span>
            </div>
        </div>
      </div>
      <div className="main-content">
        <div className="image-container">
          <img src={"DB" + step + ".png"} alt="Centered Image" className='mainchar' style={{ maxWidth: ((height / 1046) * 100) + "%" }} onClick={
            () => {
              switch(step) {
                case 1:
                case 2:
                case 3:
                  setStep(step + 1);
                  break;
                case 4:
                  setStep(1);
                  break;
              }
            }
          }/>
        <a href="#" class="button" id='mobile'>
          <div class="button-text">
            <span class="old-text">BUY $DOUGH</span>
            <span class="new-text">Coming Soon</span>
          </div>
          <img src="https://i.imgur.com/sjbs1m9.png" alt="Arrow" class="arrow-icon"></img>
        </a>
        <div className="socials-box" id='mobile-socials'>
            <h3>Follow our socials</h3>
            <div className="social-item">
                <div className="social-icon telegram"><img src='https://i.imgur.com/9oZAxNW.png'></img></div>
                <span>Telegram</span>
            </div>
            <div className="social-item">
                <div className="social-icon twitter"><img src='https://i.imgur.com/inFPDht.png'></img></div>
                <span onClick={() => window.open('https://twitter.com/doughcoineth', '_blank')} >Twitter</span>
            </div>
            <div className="social-item">
                <div className="social-icon twitter"><img src='dextools.png'></img></div>
                <span>Dextools</span>
            </div>
        </div>
        </div>
      </div>
      <div className='speech'>
        <img src={'speech' + step + '.png'} style={{width: ((width+276.923075)/3.92307692) + "px"}}></img>
      </div>
      <div className='radio' style={{top: -70 + "px"}}>
        <img src='radio.png' onClick={toggleAudio}></img>
      </div>
    </div>
  );
}

function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export default MainContent;
