import React, { useEffect, useState } from 'react';
import './styles.css';
import Header from './Header';
import MainContent from './MainContent';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import ImageUploader from './ImageUploader';

function App() {
  const [showOverlay, setShowOverlay] = useState(true);
  const [opacity, setOpacity] = useState(1);
  const [showMaker, setShowMaker] = useState(false);

  const handleButtonClick = () => {
    setOpacity(0);
    setTimeout(() => {
      setShowOverlay(false);
    }, 1000);
  };

  const handleKitchenClick = () => {
    setShowMaker(true);
  };

  return (
    <div className="main-container">
      {showOverlay && (
        <div
          style={{ opacity: opacity }}
          className={`${!showOverlay ? 'main-hidden' : 'main-overlay '}`}
        >
          <h1
            className='dough-text-loader'
            style={{ display: opacity > 0 ? 'inherit' : 'none' }}
            onClick={handleButtonClick}
          >
            <text className='reveall'>$DOUGH</text>
            <a className='reveal' style={{ display: opacity > 0 ? 'inherit' : 'none' }}>
              The Big D Energy
            </a>
          </h1>
        </div>
      )}
      {!showMaker && (
        <div className="page-content">
          <Header onButtonClick={handleKitchenClick} />
          <MainContent  />
          <Footer />
        </div>
      )}
    {showMaker && (
      <div>
        <ImageUploader></ImageUploader>
      </div>
    )}
    </div>
  );
}

export default App;
