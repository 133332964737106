import React from 'react';

const placeholderImage = "https://i.imgur.com/D6y43hN.png";

const Footer = () => (
<div className="footer">
    <div className="dotted-border">
        <div className="marquee">
            <div class="content">
                <div class="item">$DOUGH <img src={placeholderImage}/></div>
                <div class="item">$DOUGH <img src={placeholderImage}/></div>
                <div class="item">$DOUGH <img src={placeholderImage}/></div>
                <div class="item">$DOUGH <img src={placeholderImage}/></div>
                <div class="item">$DOUGH <img src={placeholderImage}/></div>

                <div class="item">$DOUGH <img src={placeholderImage}/></div>
                <div class="item">$DOUGH <img src={placeholderImage}/></div>
                <div class="item">$DOUGH <img src={placeholderImage}/></div>
                <div class="item">$DOUGH <img src={placeholderImage}/></div>
                <div class="item">$DOUGH <img src={placeholderImage}/></div>
            </div>
        </div>
    </div>
</div>
);

export default Footer;